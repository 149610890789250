import React, { useEffect, useState } from 'react';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import {
  FilterI, FilterLabelI,
  CardController, CardRequestParamsI, CardGetRespI,
  send,
  withCookie,
  ArtworkRequestParamsI,
  ArtworkController,
  ArtworkGetRespI,
} from '@oward/openapi';
import { CardGetStatus } from '@oward/common-enums';
import { ArtworkStore, CardStore, FilterStore } from '@stores/.'
import { useInjection, useTranslate } from '@hooks/.';
import { StoresBindings } from '@container/.';
import { deepEqual, GalleryType } from '@oward/common-utils';
import { NUMBER_ARTWORK_LOADED_INIT, NUMBER_CARD_LOADED_INIT, ORDER_INIT_RANDOM } from '@utils/.';
import {
  AuthenticationProps, getAuthenticationProps, WithAuthentication,
  LocaleProps, getLocaleProps, WithLocale,
  GalleryPage, objectFromQuery, getFilterLabel, ErrorPage, PageWrapper, ErrorPageGoToGallery
} from '@components/.';
import { action, toJS } from 'mobx';
import { FilterPhrase, FilterPhraseForTitle, isFilterParamValid } from '@utils/.';

export const galleryPageGetServerSideProps = async (
  locale: string,
  localeProps: LocaleProps,
  authenticationProps: AuthenticationProps,
  filterQuery: string,
  filter: FilterI,
  cookie: any
) => {
  let label: FilterLabelI;
  let pageProps: PageProps;

  try {
    label = await getFilterLabel(filter, locale);
    isFilterParamValid(filter, label);

    if (filter.gallery_type?.type === GalleryType.ARTWORK) {
      const params: ArtworkRequestParamsI = {
        limit: NUMBER_ARTWORK_LOADED_INIT,
        offset: 0,
        isRandom: ORDER_INIT_RANDOM
      }

      let artworkGetResp = await send(ArtworkController.getForGallery(locale, params, filter));

      pageProps = {
        SSR: {
          getResp: artworkGetResp,
          hasMore: artworkGetResp.totalArtworkCount > artworkGetResp.artworks?.length + params.offset,
          filterQuery: filterQuery || null,
          filter: filter,
          label: label
        }
      }
    }
    else {
      const params: CardRequestParamsI = {
        limit: NUMBER_CARD_LOADED_INIT,
        offset: 0,
        isRandom: ORDER_INIT_RANDOM
      }



      let cardGetResp = cookie === undefined ?
        await send(CardController.get(locale, params, filter))
        : await withCookie(cookie)(CardController.get(locale, params, filter));

      pageProps = {
        SSR: {
          getResp: cardGetResp,
          hasMore: cardGetResp.totalCardCount > cardGetResp.cards?.length + params.offset,
          filterQuery: filterQuery || null,
          filter: filter,
          label: label
        }
      }
    }
  }
  catch (err) {
    pageProps = { errMsg: (err as Error)?.message }
  }

  return {
    props: {
      ...pageProps,
      ...authenticationProps,
      ...localeProps
    },
  };
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const locale: string = context.params.lang as string;
  const localeProps = await getLocaleProps(locale);
  const authenticationProps = await getAuthenticationProps(context);

  const filterQuery = context.query['filter'] as string;
  const filter = objectFromQuery<FilterI>(filterQuery);

  // Authentication is needed when getting cards to verify we're not accessing a private shortlist
  let cookie = context.req.headers.cookie;

  return galleryPageGetServerSideProps(locale, localeProps, authenticationProps, filterQuery, filter, cookie);
}

var SSR = true;

interface PageProps {
  SSR?: {
    getResp: CardGetRespI | ArtworkGetRespI;
    filterQuery: string;
    filter: FilterI;
    label: FilterLabelI;
    hasMore: boolean;
  },
  errMsg?: string;
}

const Page: React.FC<PageProps & LocaleProps & AuthenticationProps> = props => {
  const router = useRouter();
  const { t } = useTranslate();
  const artworkStore = useInjection<ArtworkStore>(StoresBindings.ARTWORK);
  const cardStore = useInjection<CardStore>(StoresBindings.CARD);
  const filterStore = useInjection<FilterStore>(StoresBindings.FILTER);
  const [label, setLabel] = useState(props.SSR?.label);
  const [filter, setFilter] = useState(props.SSR?.filter);
  const labelQuery = router.query['label'];
  const filterQuery = router.query['filter'];

  useEffect(() => {
    if (!SSR) {
      if (labelQuery !== undefined) {
        setLabel(objectFromQuery<FilterLabelI>(labelQuery as string, true));
      }
      if (filterQuery !== undefined) {
        setFilter(objectFromQuery<FilterI>(filterQuery as string));
      }
    }

    SSR = false;
  }, [labelQuery, filterQuery]);

  useEffect(() => {
    if (!deepEqual(filter, toJS(filterStore.filter))) {
      // Don't re-filter if there's a page change but the filter didn't change (for ex : modals)
      filterStore.init(filter, label);
      if (filterStore.isArtworkGallery) {
        artworkStore.getArtworks(filterStore.filter)
      }
      else {
        cardStore.getCards(filterStore.filter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  if (props.errMsg) {
    return (
      <PageWrapper>
        {(props.errMsg === 'Bad Request')
          ?
          <ErrorPageGoToGallery
            errMsg={t('global.error_wrong_url')}
            icon={'fa-2x fa-solid fa-triangle-exclamation'}
          />
          :
          <ErrorPage errMsg={props.errMsg} />
        }
      </PageWrapper>
    )
  }

  action(() => {
    if (SSR) {
      if (label.film_year === '0') {
        label.film_year = t('wip.film');
      }
      filterStore.init(filter, label);

      if (filterStore.isArtworkGallery) {
        artworkStore.artworks = (props.SSR.getResp as ArtworkGetRespI).artworks;
        artworkStore.totalArtworkCount = (props.SSR.getResp as ArtworkGetRespI).totalArtworkCount;
        artworkStore.hasMore = props.SSR.hasMore;
      }
      else {
        cardStore.cards = (props.SSR.getResp as CardGetRespI).cards;
        cardStore.totalCardCount = (props.SSR.getResp as CardGetRespI).totalCardCount;
        cardStore.cardGetStatus = (props.SSR.getResp as CardGetRespI).status as CardGetStatus;
        cardStore.hasMore = props.SSR.hasMore;
      }

    }
  })();

  return (
    <PageWrapper
      pageTitle={FilterPhraseForTitle(filter, label)}
      pageDescription={t('header.description_with_filter', { filter_phrase: FilterPhrase(filter, label) })}
    >
      <GalleryPage />
    </PageWrapper>
  );
}

const CompletePage: React.FC<PageProps & LocaleProps & AuthenticationProps> = props => {
  return (
    <WithLocale {...props}>
      <WithAuthentication {...props}>
        <Page {...props} />
      </WithAuthentication>
    </WithLocale>
  );
}

export default CompletePage;
